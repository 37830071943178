import React from "react";
import Layout from "../../components/Layout";
import ProjectRoll from "../../components/ProjectRoll";
import "../../styles/global.css";

export default class ProjectIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="full-width-image-container margin-top-0">
          <h2
            className="has-text-weight-bold is-size-1"
            style={{
              backgroundColor: "#212529",
              color: "#212529",
              padding: "1rem"
            }}
          >
            Projects
          </h2>
        </div>
        <section className="section">
          <div className="container" style={{ padding: "4rem 0" }}>
            <h2>Projects</h2>
            <ProjectRoll />
          </div>
        </section>
      </Layout>
    );
  }
}
